import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import DB_img from '../../static/images/ser-db.jpg';
import Jde_img from '../../static/images/ser-jde.jpg';
import HS_img from '../../static/images/ser-hs.jpg';
import Mobsol_img from '../../static/images/ser-mobsol.jpg';
import BI_img from '../../static/images/ser-bi.jpg';
import Cloud_img from '../../static/images/ser-cloud.jpg';

function Service() {
  return (
    <Container id='service'>
      <div className='text-center justify-content-center '>
      <h1 className='text-white mt-5 mb-4'>Everything you Need to Know</h1>
      </div>
    <Row  xs={1} md={3} className="g-1" style={{paddingLeft:0, paddingRight:0}}>
     
        <Col>
          <Card bg='Light text-center'>
            <Card.Img variant="top" src={Jde_img} />
            <Card.Body>
              <Card.Link href="/services" > Oracle JD Edwards</Card.Link>
              
            </Card.Body> 
          </Card>
        </Col>
        <Col>
          <Card bg='Light text-center'>
            <Card.Img variant="top" src={Cloud_img} />
            <Card.Body>
              <Card.Link href="/services">Cloud Services</Card.Link>
              
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg='Light text-center'>
            <Card.Img variant="top" src={Mobsol_img} />
            <Card.Body>
              <Card.Link href="/services">Mobility Solutions</Card.Link>
              
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg='Light text-center'>
            <Card.Img variant="top" src={BI_img} />
            <Card.Body>
              <Card.Link href="/services">Oracle BI Services</Card.Link>
              
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg='Light text-center'>
            <Card.Img variant="top" src={DB_img} />
            <Card.Body>
              <Card.Link href="/services">Database Services</Card.Link>
              
            </Card.Body>
          </Card>
        </Col>    
        
        <Col className='mb-5'>
          <Card bg='Light text-center'>
            <Card.Img variant="top" src={HS_img} />
            <Card.Body>
              <Card.Link href="/services">Help & Support</Card.Link>
              {/* <Card.Text>
                This is a longer card with supporting text below as a natural
              </Card.Text> */}
            </Card.Body>
          </Card>
        </Col>    
      </Row>
    </Container>
  );
}

export default Service;