import React from 'react'

function Career() {
    return (
        <div className='text-white text-center my-lg-5'>
              <h2 className='my-4'>Sorry!</h2>
              <h3 className='mt-3'>No current openings.</h3>
              
              {/* <Button variant="outline-info" type="submit">Submit</Button> */}
            </div>
    )
}

export default Career
