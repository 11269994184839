import React from 'react';
import { CDBFooter, CDBBtn, CDBIcon, CDBBox } from 'cdbreact';
import logo from '../../static/images/Emcube-1.png'
// import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

function Footer() {
  return (

    <CDBFooter className="shadow ">
      <CDBBox display="flex" flex="column" className="container py-5" style={{ width: '70%' }}>
        <CDBBox display="flex" justifyContent="between" className="flex-wrap">
          <CDBBox>
            <a href="/" className="d-flex align-items-center p-0 text-light">
              <img alt="logo" src={logo} width="50"
              height="30" />
              <span className="ml-3 ms-2 h5 font-weight-bold">Emcube</span>
            </a>
            <p className="my-3 text-light" style={{ width: '250px' }}>
            Emcube Cloud is an Information Technology (IT) Infrastructure Consultancy and Services company providing solutions to help companies build, secure, and manage their complex Information Technology infrastructures.

            </p>

          </CDBBox>
          <CDBBox>
            <p className="h5 mb-4 text-light" style={{ fontWeight: '600' }}>
            Emcube
            </p>
            <CDBBox flex="column" display="flex" style={{ cursor: 'pointer', padding: '0' }}>

            <a href="/services">Services</a>
              <a href="/career">Career</a>
              {window.location.href === ('http://emcubecloud.com/' ||  'http://emcubecloud.com/#contact' ||  'http://emcubecloud.com/#service') ?
                <a href="/#contact">Contact</a> :
                <a href="/contact">Contact</a>
              }
            </CDBBox>
            <p className="my-3 text-light" style={{ width: '250px' }}>
            701 Dhara Cornor, Sector 10, Taloja Panchnand, Mumbai.
            </p>
            <span>
              <p className="my-3 text-light" style={{ width: '250px' }}> sales@emcubecloud.com
            </p>
            </span>


          </CDBBox>
          {/* <CDBBox>
            <p className="h5 mb-4 text-light" style={{ fontWeight: '600' }}>
              Help
            </p>
            <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', padding: '0' }}>
            <a href="/services">Services</a>
              <a href="/career">Career</a>
            </CDBBox>
          </CDBBox> */}

        </CDBBox>
        <CDBBox
          display="flex"
          justifyContent="center"
          style={{ width: '100%' }}
          className="mx-auto mt-4"
        >
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="facebook-f" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="mx-3 p-2">
            <CDBIcon fab icon="twitter" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="instagram" />
          </CDBBtn>
        </CDBBox>
        <small className="text-center mt-5 text-light">&copy; Emcube, 2022. All rights reserved.</small>
      </CDBBox>
    </CDBFooter>
  );
}
export default Footer