import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Banner  from '../static/images/banner-5bps.mp4';
import Container from 'react-bootstrap/esm/Container';
import DB_img from '../static/images/ser-db.jpg';
import Jde_img from '../static/images/jde.jpg';
import HS_img from '../static/images/ser-hs.jpg';
import Mobsol_img from '../static/images/ser-mobsol.jpg';
import BI_img from '../static/images/ser-bi.jpg';
import Cloud_img from '../static/images/ser-cloud.jpg';

function Service() {
    return (
    <div>
        <div>
            <video style={{width:'100%', height:'350px',objectFit: 'cover',position:'absolute'}} preload="auto" autobuffer="" autoPlay muted loop playsinline=""  src={Banner} type="video/mp4" />
            <div className='container d-flex justify-content-center  h-100 ' style={{position: 'relative', paddingTop:'140px'}}>
                    <div className='text-white text-center my-lg-5' >
                        <h1 className='mt-4'>Services</h1>
                        <h3 className='mt-2 mb-5' >On which we support our customers.</h3>

                    </div>
            </div>
                
        </div>
            
    <Container>
      <Row className='my-4 text-center pt-lg-0 pt-md-5'>
        <Col sm={12} md={6}>
        <img style={{width:'100%'}} src = {Jde_img} alt=''></img>
        </Col>
        <Col>
        <h2 className='text-white'>
        Oracle JD Edwards   
        </h2>
        <p className='text-white mt-3'>
        JD Edwards practice blends technical and domain knowledge to deliver business solutions.
         Our extensive experience in JD Edwards and rich domain experience enable us to use JD Edwards to your business advantage and maximize returns on existing investments.
        </p>
        </Col>
      </Row>
      <Row className='my-5 text-center '>
      <Col >
        <h2 className='text-white'>
        Cloud Services
        </h2>
        <p className='text-white mt-3'>
        Enterprises are seeking ways of leveraging cloud technology to develop and optimize organizational success. Enterprises are looking for a new platform, that will provide an opportunity to make significant changes in productivity while achieving critical, cost-effective, growth initiatives.
        </p>
        </Col>
        <Col md={6} sm={12}>
        <img style={{width:'100%'}} src = {Cloud_img} alt=''></img>
        </Col>
      </Row>
      <Row className='my-5 text-center '>
      <Col md={6} sm={12}>
        <img style={{width:'100%'}} src = {Mobsol_img} alt=''></img>
        </Col>
        <Col>
        <h2 className='text-white'>
        Mobility Solutions   
        </h2>
        <p className='text-white mt-3'>
        The entire system that allows enterprises to "make mobile" company data and business processes.From a business functional point of view it allows the employees to work anywhere at any time and have the tools and information they need to do their job.
        </p>
        </Col>
      </Row>
      <Row className='my-5 text-center'>
        <Col >
        <h2 className='text-white'>
        Database Services   
        </h2>
        <p className='text-white mt-3'>
        We are leading service providers for DBA services (Oracle, SQL and AS-400) for clients from various industries. We, at Emcube with our dedicated and expert professionals help our clients to achieve their goals. We work closely with our clients to make sure that they are able to get what they desire.
        </p>
        </Col>
        <Col md={6} sm={12}>
        <img style={{width:'100%'}} src = {DB_img} alt=''></img>
        </Col>
      </Row>
      <Row className='my-5 text-center '>
        <Col md={6} sm={12}>
        <img style={{width:'100%'}} src = {BI_img} alt=''></img>
        </Col>
        <Col>
        <h2 className='text-white'>
        Oracle BI Services 
        </h2>
        <p className='text-white mt-3'>
        Oracle Business Intelligence refers to the process of collecting data and converting it into information in order to create a competitive advantage, to improve business processes and to increase the quality of data. Collecting and analysing information on customers, competitors and market developments helps you take better decisions to support your business strategy.
        </p>
        </Col>
      </Row>
      <Row className='my-5 text-center '>
        <Col>
        <h2 className='text-white'>
        Help & Support 
        </h2>
        <p className='text-white mt-3'>
        Emcube Cloud ensures that you realize the full potential of the product that you have invested in. Services offered include Online Technical Team Support, Online Self Assisted Support and Offline Support.
Emcube Cloud experts have extensive project experience which include: JD Edwards Implementation JD Edwards Upgrades JD Edwards Application Maintenance and Support JD Edwards Advisory Services.
        </p>
        </Col>
        <Col md={6} sm={12}>
        <img style={{width:'100%'}} src={HS_img} alt=''></img>
        </Col>
      </Row>
      </Container>
      </div>
    )
}

export default Service
