import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoute from './Routes/route';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <AppRoute />
  );
}  
export default App;
