export async function ContactApi(data) {
    let result=  await fetch('http://emcubecloud.com/api/contact_info', {
        method:'POST',
        headers: {
            'Content-Type' : 'application/json'

        },
        mode: "cors",
        body: JSON.stringify(data)
    })
    return result;
}


