import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../static/images/Emcube-1.png'

function Appbar() {
  return (
    <Navbar bg="dark" variant='dark' className='sticky-top ps-5 pe-5 ' expand="lg">
      <Navbar.Brand href="/">
            <img
              src={logo}
              width="80"
              height="50"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className=" my-2"  >
          <Nav
            className="my-2 my-lg-0 "
            style={{ maxHeight: '300px', }}
            navbarScroll
          >
            <Nav.Link href="/career">Careers</Nav.Link>
            {window.location.href === ('http://emcubecloud.com/' ||  'http://emcubecloud.com/#contact' ||  'http://emcubecloud.com/#service') ?
              <Nav.Link href='/#contact'>Contact</Nav.Link> :
              <Nav.Link href='/contact'>Contact</Nav.Link>
            }
            <Nav.Link href="/services">Services</Nav.Link>
            {/* <NavDropdown title="Service" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Service</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <InputGroup  className='searchbox'  >

            <Form.Control

              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <Button  variant="outline-primary">Search</Button>
          </InputGroup>
        </Navbar.Collapse>
    </Navbar>
  );
}

export default Appbar;