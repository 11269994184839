import '../style/style.css';
import React from 'react'
import { Outlet } from "react-router-dom";
import Appbar from "./Home/appbar.js";
import Footer  from "./Home/footer.js";
import {ToastContainer, toast} from 'react-toastify';

function Commondashboard() {
    return (
        <div  style={{ backgroundColor: '#111418' }}>
            <ToastContainer width={400} 
                    position={toast.POSITION.TOP_CENTER} 
                    autoClose={2000}
                />
            <Appbar />
            
            <Outlet />
            <Footer/>
        </div>
    )
}

export default Commondashboard
