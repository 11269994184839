import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactImage from '../../static/images/hc.jpg';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { ContactApi } from '../../apis/api.js';
import Button from 'react-bootstrap/Button';

const formField={
  'first_name':'',
  'last_name':'',
  'email':'',
  'phone':'',
  'message':''
}

function Contact() {
  const [validated, setValidated] = useState(false);
  const [contactData, setContactData] = useState(formField);

  const  handleSubmit = async (event) => {

      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
      }
      else{

        let res = await ContactApi(contactData);
        if (res.status === 200 ){
            res.text().then(function (text) { // This line will get the msg
              toast.success(text)
            });
        }
        else if(res.status === 409 ){
          res.text().then(function (text) { // This line will get the msg
            toast.info(text)
          });
            setValidated(false);
            setContactData(formField);

        }

      }

    };

    const updateField = (event) => {
      const value = event.currentTarget.value;
      const field = event.target.name;
      contactData[field] = value;
      setContactData({...contactData});
    };

    console.log('formdata', contactData);
    return (
    <div
        className='bg-image' id='contact'
        style={{ backgroundImage:`url(${ContactImage})`, backgroundSize: 'cover' }}
      >
        <div className='mask d-flex' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='container py-4'>
            <div className='text-white my-lg-3' style={{float:"right"}} >
              <h1 className='my-3'>Ready to Shine?</h1>
              <h3 className='my-3'>Contact us...</h3>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>First name</Form.Label>
                      <Form.Control
                        name='first_name'
                        required
                        type="text"
                        placeholder="First name"
                        onChange={updateField}
                        value={contactData.first_name}
                      />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                    name='last_name'
                      required
                      type="text"
                      placeholder="Last name"
                      onChange={updateField}
                      value={contactData.last_name}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" as={Col} md="12" controlId="validationCustomUsername">
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="email"
                        name='email'
                        placeholder="Email"
                        aria-describedby="inputGroupPrepend"
                        required
                        onChange={updateField}
                      value={contactData.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide valid email id.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="12" controlId="validationCustomUsername">
                    <Form.Label>Phone No.</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="tel"  name="phone" pattern="[0-9]{10}"
                        placeholder="Phone No."
                        aria-describedby="inputGroupPrepend"
                        required
                        onChange={updateField}
                      value={contactData.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide valid phone number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md="12" controlId="validationCustomUsername">
                  <Form.Label>Message</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control as="textarea"
                    name='message'
                    placeholder="Leave a message here..."
                    aria-label="With textarea"
                    required
                    style={{ height: '100px' }}
                    onChange={updateField}
                    value={contactData.message}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide message.
                    </Form.Control.Feedback>
                  </InputGroup>

                  </Form.Group>
                {/* <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="City" required />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid city.
                    </Form.Control.Feedback>
                  </Form.Group>

                </Row> */}

                {/* <FloatingLabel controlId="floatingTextarea2" label="Comments">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    type="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: '100px' }}
                  />
                </FloatingLabel> */}
                {/* <Button variant="outline-info">Submit form</Button> */}
                <p>For more queries. Contact us @ +91 9819026861</p>
                <Button variant="outline-light" type="submit">Submit</Button>
              </Form>
            </div>
          </div>
        </div>
</div>
  )
};
export default Contact

