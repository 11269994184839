import React from 'react';
import {Route,  Routes} from 'react-router-dom';
import Commondashboard from '../Components/commondashboard';
import Career from '../Components/career.js';
import Home from '../Components/home';
import Service from '../Components/service.js';
import Contact from '../Components/Home/contact.js';


export default function AppRoute() {
    console.log('url',window.location.href)
    return (
       
        <Routes>
            <Route path="/" element = {<Home />}>
            </Route>
            <Route element = {<Commondashboard />}>
                <Route path="/career" element = {<Career />}></Route>
                <Route path="/contact" element = {<Contact />}></Route>
                <Route path="/services" element = {<Service />}></Route>
            </Route>
        </Routes>
        
    )
};

