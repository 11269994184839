import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderImage from '../../static/images/jd-edwards-software-support-1.webp';

function Header() {
    return (
    //     <div
    //     class="bg-image p-5 text-center shadow-1-strong rounded mb-5 text-white"
    //     style={{backgroundImage:`url(${HeaderImage})`,height: 400 }}
    //   >
    //     <h1 class="mb-3 h2">Jumbotron</h1>
      
    //     <p>
    //       Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus praesentium
    //       labore accusamus sequi, voluptate debitis tenetur in deleniti possimus modi voluptatum
    //       neque maiores dolorem unde? Aut dolorum quod excepturi fugit.
    //     </p>
    //   </div>
    <div
        className='text-left bg-image'
        style={{ backgroundImage:`url(${HeaderImage})`, height: 'auto' }}
      >
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='container d-flex justify-content-left align-items-center h-100 py-4'>
            <div className='text-white my-lg-5'>
              <h1 className='my-4'>Our Aim</h1>
              <h3 className='mt-3'>To help you upgrade the World system,</h3>
              <h3 className='mb-3'>Quickly and Efficiently.</h3>
              <a className='btn btn-outline-light btn-lg my-4' href='#service' role='button'>
                Get Started
              </a>
              {/* <Button variant="outline-info" type="submit">Submit</Button> */}
            </div>
          </div>
        </div>
    </div>
    );
}
export default Header

