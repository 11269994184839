import '../style/style.css';
import { Outlet } from "react-router-dom";
import Appbar from "./Home/appbar.js";
import Header from "./Home/header.js";
import Service  from "./Home/service.js";
import Footer  from "./Home/footer.js";
import Contact from "./Home/contact.js";
import {ToastContainer, toast} from 'react-toastify';

export default function Home() {
    return(

        <div  style={{ backgroundColor: '#111418' }}>
                <ToastContainer width={400}
                    position={toast.POSITION.TOP_CENTER}
                    autoClose={2000}
                />
            <Appbar />
            <Header/>
            <Service/>
            <Contact/>
            <Footer/>
            <Outlet />
        </div>
    )
}